import React, { useEffect, useState } from "react";
import { default as axios } from "axios";

const { ipcRenderer } = window.require
  ? window.require("electron")
  : { ipcRenderer: undefined };

const Home: React.FC = () => {
  enum Status {
    Unauthenticated,
    Authenticating1,
    Authenticated1,
    Authenticating2,
    Authenticated2,
    Loading,
    Loaded,
  }

  interface RaottData {
    raott: string;
    startDate: string;
    endDate: string;
    context?: any;
  }
  const emptyRaottData: RaottData = {
    raott: "",
    startDate: "",
    endDate: "",
  };

  interface EventData {
    rosterEntryId: string;
    eventId: string;
    description: string;
  }

  interface RosterData {
    personId: string;
    catapultId: string;
    items: EventData[];
  }

  interface StateData {
    isElectron: boolean;
    username: string;
    password: string;
    isPro: boolean;
    error: string;
    status: Status;
    raottData: RaottData;
    rmt?: any;
    roster?: RosterData;
    context?: { [key: string]: string };
  }

  const [state, setState] = useState({
    isElectron: ipcRenderer !== undefined,
    username: "",
    password: "",
    isPro: false,
    error: "",
    status: Status.Unauthenticated,
    raottData: emptyRaottData,
  } as StateData);

  const handleRmtRetrieve = (event: any, props: any) => {
    if (props) {
      setState((prevState: StateData) => {
        return {
          ...prevState,
          raottData: props,
          loading: Status.Authenticating2,
        };
      });
    } else {
      setState((prevState: StateData) => {
        return {
          ...prevState,
          raottData: emptyRaottData,
          loading: Status.Unauthenticated,
        };
      });
    }
  };

  useEffect(() => {
    document.title = "DAP Auth Spike";
    if (state.isElectron) {
      ipcRenderer.on("RmtData", handleRmtRetrieve);
      checkForLocalRmt();
    }
    const { iam } = window.cb.core;
    const { events } = iam;

    const iamBus = iam.getEventBus();
    iamBus.on(events.AWSLogin, () => handleLoginSuccess());
    iamBus.on(events.Logout, () => handleLogoutSuccess());
    iamBus.on(events.AWSLogoutStarted, () => handleLogoutStarted());
    iamBus.on(events.AWSLoginStarted, () => handleLoginStarted());
    iamBus.on(events.AWSLoginRefresh, () => handleLoginRefresh());
    iamBus.on(events.AWSLoginFailed, () => handleLoginFailed());

    // eslint-disable-next-line
  }, []);

  const handleLoginSuccess = () => {
    console.log("Login Success");

    const authzToken = window.cb.core.iam.getAuthorizationToken();
    const token = JSON.parse(atob(authzToken.split(".")[1]));

    setState((prevState) => {
      return {
        ...prevState,
        context: token?.cb?.context,
        status: Status.Authenticated2,
      };
    });
    tryGetRoster();
  };
  const handleLogoutSuccess = () => {
    console.log("Logout Success");
    setState((prevState) => {
      return { ...prevState, status: Status.Unauthenticated };
    });
  };
  const handleLogoutStarted = () => {
    console.log("Logout Started");
  };
  const handleLoginStarted = () => {
    console.log("Login Started");
    setState((prevState) => {
      return { ...prevState, status: Status.Authenticated2 };
    });
  };
  const handleLoginRefresh = () => {
    console.log("Login Refresh");
  };
  const handleLoginFailed = () => {
    console.log("Login Failed");
    setState((prevState) => {
      return { ...prevState, status: Status.Unauthenticated };
    });
  };

  const tryRaottLogin = () => {
    if (state.raottData.raott) {
      console.log("Logging with Raott");
      window.cb.core.iam.loginWithRAOTT(state.raottData.raott);
      setState((prevState) => {
        return { ...prevState, status: Status.Authenticating2 };
      });
    } else {
      console.log("Skipped login with RAOTT because there is no RAOTT");
      setState((prevState) => {
        return { ...prevState, status: Status.Unauthenticated };
      });
    }
  };

  const tryGetRoster = () => {
    const iam = window.cb.core.iam;
    const authnToken = iam.getAuthenticationToken();
    if (!authnToken) {
      console.log("Cannot get Roster: missing Authn");
      return;
    }
    const authzToken = iam.getAuthorizationToken();
    if (!authzToken) {
      console.log("Cannot get Roster: missing Authz");
      return;
    }
    setState((prevState) => {
      return { ...prevState, status: Status.Loading };
    });
    axios
      .get("/api/roster", {
        headers: {
          "X-CB-Catapult-Authorization-Token": window.cb.core.iam.getAuthorizationToken(),
          "X-CB-Catapult-Authentication-Token": window.cb.core.iam.getAuthenticationToken(),
        },
      })
      .then((response) => {
        setState((prevState) => {
          return {
            ...prevState,
            status: Status.Loaded,
            roster: response.data,
          };
        });
      });
  };

  useEffect(() => {
    if (state.raottData.raott !== "") {
      tryRaottLogin();
      setState((prevState: StateData) => {
        return {
          ...prevState,
          authing: true,
        };
      });
    }
    // eslint-disable-next-line
  }, [state.raottData.raott]);

  const checkForLocalRmt = () => {
    tryRetrieveRaott();
    setState((prevState: StateData) => {
      return { ...prevState, loading: true };
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!state.username) {
      setState({ ...state, error: "Username is required" });
      return;
    }

    if (!state.password) {
      setState({ ...state, error: "Password is required" });
      return;
    }
    setState({ ...state, status: Status.Authenticating1 });
    tryLogin(state.username, state.password, state.isPro);
  };

  const tryLogin = (username: string, password: string, isPro: boolean) => {
    axios
      .post("/api/login", {
        username: username,
        password: password,
        isPro: isPro,
        personId: "17159680",
      })
      .then((response: any) => {
        setState({
          ...state,
          status: Status.Authenticated1,
          raottData: response.data,
        });
        tryStoreRaott(response.data);
      })
      .catch((error: any) => {
        if (error.response) {
          setState({
            ...state,
            status: Status.Unauthenticated,
            error: error.response.data?.reason,
          });
        } else if (error.request) {
          setState({
            ...state,
            status: Status.Unauthenticated,
            error:
              "Unable to make a request to the server. " +
              JSON.stringify(error.request),
          });
        } else {
          setState({
            ...state,
            status: Status.Unauthenticated,
            error: JSON.stringify(error),
          });
        }
      });
  };

  const tryStoreRaott = (data: RaottData) => {
    if (state.isElectron) ipcRenderer.send("storeRmt", data);
  };

  const tryRetrieveRaott = () => {
    if (state.isElectron) ipcRenderer.send("retrieveRmt", {});
  };

  const dismissError = () => {
    setState({ ...state, error: "" });
  };

  const handleUserChange = (event: any) => {
    setState({ ...state, username: event.target.value });
  };

  const handlePassChange = (event: any) => {
    setState({ ...state, password: event.target.value });
  };

  const handleProChange = (event: any) => {
    setState({ ...state, isPro: event.target.checked });
  };

  const renderContext = (context: any) => {
    const list: any[] = [];
    if (context) {
      const values = Object.values(context);
      Object.keys(context).forEach((k, idx) => {
        list.push(
          <span>
            <label>{k}: </label>
            {values[idx]}
            <br />
          </span>
        );
      });
    }
    return list;
  };

  const renderRoster = (roster: RosterData) => {
    const list: any[] = [];
    list.push(
      <span>
        <label>Person ID: </label>
        {roster.personId}
        <br />
      </span>
    );
    list.push(
      <span>
        <label>Catapult ID: </label>
        {roster.catapultId}
        <br />
      </span>
    );
    list.push(
      <span>
        <h4>Event Registrations</h4>
        <ul>
          {roster.items.map((item) => {
            return (
              <li>
                {item.description} [Event: {item.eventId}, RosterEntryId:{" "}
                {item.rosterEntryId}]
              </li>
            );
          })}
        </ul>
      </span>
    );
    return list;
  };
  const body = () => {
    if (state.status === Status.Loading) {
      return <h3>Loading...</h3>;
    } else if (state.status === Status.Authenticating1) {
      return <h3>Authenticating with Full Login...</h3>;
    } else if (state.status === Status.Authenticating2) {
      return <h3>Verifying RMT...</h3>;
    } else if (state.status === Status.Loaded) {
      return (
        <div>
          <h3>Raott: </h3>
          {state.raottData.raott}
          <h3>Context:</h3>
          {renderContext(state.context)}
          <h3 data-test="rosterData">Roster</h3>
          {state.roster && renderRoster(state.roster)}
          <br />
        </div>
      );
    } else {
      return (
        <div className="Login">
          <form onSubmit={handleSubmit}>
            {state.error && (
              <h3 data-test="error" onClick={dismissError}>
                <button onClick={dismissError}>✖</button>
                {state.error}
              </h3>
            )}
            {state.raottData.raott && (
              <div>
                <h3 data-test="raottData">Raott Data</h3>
                <label>Raott: </label>
                {state.raottData.raott}
                <br />
                <label>Start Date: </label>
                {state.raottData.startDate}
                <br />
                <label>End Date: </label>
                {state.raottData.endDate}
                <br />
                <label>Context: </label>
                {state.raottData.context}
                <br />
              </div>
            )}
            {state.status === Status.Authenticated2 && (
              <div>
                <h4>Logged in</h4>
              </div>
            )}
            <br />
            <label>User Name: </label>
            <input
              type="text"
              data-test="username"
              value={state.username}
              onChange={handleUserChange}
            />
            <br />
            <br />
            <label>Password: </label>
            <input
              type="password"
              data-test="password"
              value={state.password}
              onChange={handlePassChange}
            />
            <br />
            <br />
            <label>Is Professional: </label>
            <input
              type="checkbox"
              data-test="isPro"
              checked={state.isPro}
              onChange={handleProChange}
            />
            <br />
            <br />
            <input type="submit" value="Log In" data-test="submit" />
          </form>
        </div>
      );
    }
  };

  return body();
};

export default Home;
